import { useLocation } from "@reach/router"
import axios from "axios"
import DOMPurify from "dompurify"
import { parse as queryparse } from "query-string"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { errors as ErrorMessages } from "../../constants/errorMessages"
// import Select from "../utility/select"
import { productType, serverRegions } from "../../constants/globalMessages"
import { BrowserDetails } from "../utility/browserDetails"
import {
  checkCommonDomain,
  checkInvalid,
  checkInvalidCompany,
  checkValidEmail,
  checkValidName,
} from "./../utility/validation"
import { setHubspotutkCookie } from "./FormBuilder/Form-Function"
import PopupPlanTech from "./popup-plantech"

const SignupFormTest = forwardRef((props, ref) => {
  const [isAPILoading, setIsAPILoading] = useState(false)
  const [disableButton, setDisableButton] = useState()
  const locationnew = useLocation()
  const searchParams = queryparse(locationnew.search)

  const [humioLogAvailable, setHumioLogAvailable] = useState(false)

  const [defaultCountryCode, setDefaultCountryCode] = useState("us")
  const [dailCodeLength, setDailCodeLength] = useState(0)

  const [name, setName] = useState("")
  const [namef, setNamef] = useState("")
  const [namel, setNamel] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNum, setPhone] = useState("")
  const [selectedOptions, setSelectedOptions] = useState("")
  const [company, setCompany] = useState("")
  const [apiphoneNumberCountryCode, setapiphoneNumberCountryCode] = useState("")

  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [companyError, setCompanyError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [currentToolError, setCurrentToolError] = useState(false)

  const [isInvalidEmail, setIsInvalidEmail] = useState(false)

  const [countryPhoneCode, setCountryPhoneCode] = useState(null)

  const [nameIsOffFocus, setNameIsOffFocus] = useState(false)
  const [emailIsOffFocus, setEmailIsOffFocus] = useState(false)
  const [compIsOffFocus, setCompIsOffFocus] = useState(false)
  const [phoneIsOffFocus, setPhoneIsOffFocus] = useState(false)

  const [growsumo, setGrowsumo] = useState()

  const [popup, setPopup] = useState(false)
  const [planType, setPlanType] = useState()
  const [techCount, setTechCount] = useState()

  useEffect(() => {
    if (searchParams?.src === "xero") {
      setName(searchParams.name)
      setEmail(searchParams.mail)
      setCompany(searchParams.company)
    }
    setGrowsumo(window.growsumo)
  }, [])

  useImperativeHandle(ref, () => ({
    log: () => {
      return !name && !email && !company && !phoneNum.slice(dailCodeLength)
    },
  }))

  function checkCurrencySupport(currencyCode) {
    const supported_currencies = [
      "AED",
      "AUD",
      "BHD",
      "CAD",
      "EUR",
      "NZD",
      "QAR",
      "SAR",
      "SGD",
      "USD",
      "ZAR",
      "GBP",
    ]

    return supported_currencies.includes(currencyCode) ? currencyCode : "USD"
  }

  function BrowserType() {
    let Browser,
      UsrAg = navigator.userAgent

    if (UsrAg.indexOf("Firefox") > -1) {
      Browser = "Mozilla Firefox"
    } else if (UsrAg.indexOf("SamsungBrowser") > -1) {
      Browser = "Samsung Internet"
    } else if (UsrAg.indexOf("Opera") > -1 || UsrAg.indexOf("OPR") > -1) {
      Browser = "Opera"
    } else if (UsrAg.indexOf("Trident") > -1) {
      Browser = "Microsoft Internet Explorer"
    } else if (UsrAg.indexOf("Edge") > -1) {
      Browser = "Microsoft Edge (Legacy)"
    } else if (UsrAg.indexOf("Edg") > -1) {
      Browser = "Microsoft Edge (Chromium)"
    } else if (UsrAg.indexOf("Chrome") > -1) {
      Browser = "Google Chrome or Chromium"
    } else if (UsrAg.indexOf("Safari") > -1) {
      Browser = "Apple Safari"
    } else {
      Browser = "unknown"
    }

    return "You are using: " + Browser
  }

  const checkHumioLogger = () => {
    if (window.logger) {
      console.log("Humio logger available")
      !humioLogAvailable && setHumioLogAvailable(true)
    } else {
      console.log("Humio logger not-available")
      humioLogAvailable && setHumioLogAvailable(false)
    }
  }

  let choices = [
    { label: "Datto", value: "Datto" },
    { label: "NinjaRMM", value: "NinjaRMM" },
    { label: "Kaseya", value: "Kaseya" },
    { label: "Atera", value: "Atera" },
  ]

  function CheckIpdata(resdata, alt) {
    const data = resdata != null && resdata !== "" ? resdata : alt
    return data
  }

  useEffect(() => {
    if (props.setInputEmail) {
      setEmail(props.setInputEmail)
    }
  }, [props.setInputEmail])

  const [IpData, setIpData] = useState({
    continent: null,
    continentcode: null,
    countrycode: null,
    country: null,
    state: null,
    city: null,
    zip: null,
    timezone: null,
    currencycode: null,
    countryphoneCode: 0,
  })

  useEffect(() => {
    let checkIsReferral = sessionStorage.getItem("sopsreff")
    checkHumioLogger()
    axios.get(process.env.IP_STACK_API).then(function(response) {
      if (response?.data?.country_code) {
        setIpData({
          continent: CheckIpdata(
            response?.data?.continent_name,
            IpData.continent
          ),
          continentcode: CheckIpdata(
            response?.data?.continent_code,
            IpData.continentcode
          ),
          countrycode: CheckIpdata(
            response?.data?.country_code,
            IpData.countrycode
          ),
          country: CheckIpdata(response?.data?.country_name, IpData.country),
          state: CheckIpdata(response?.data?.region_name, IpData.state),
          city: CheckIpdata(response?.data?.city, IpData.city),
          zip: CheckIpdata(response?.data?.zip, IpData.zip),
          timezone: CheckIpdata(response?.data?.time_zone, IpData.timezone),
          currencycode: CheckIpdata(
            response?.data?.currency?.code,
            IpData.currencycode
          ),
          countryphoneCode: CheckIpdata(
            response?.data?.location?.calling_code,
            IpData.countryphoneCode
          ),
        })
        const cPhoneCode = response?.data?.location?.calling_code
        setCountryPhoneCode(
          cPhoneCode?.includes(",") ? cPhoneCode?.split(",")[0] : cPhoneCode
        )
        setDefaultCountryCode(response?.data?.country_code?.toLowerCase())
        props.setServerCountry &&
          props.setServerCountry(response?.data?.continent_code || "US")
      } else {
        axios.get(process.env.IP_REGISTRY_API).then(response => {
          setIpData({
            continent: CheckIpdata(
              response?.data?.location?.continent?.name,
              IpData.continent
            ),
            continentcode: CheckIpdata(
              response?.data?.location?.continent?.code,
              IpData.continentcode
            ),
            countrycode: CheckIpdata(
              response?.data?.location?.country?.code,
              IpData.countrycode
            ),
            country: CheckIpdata(
              response?.data?.location?.country?.name,
              IpData.country
            ),
            state: CheckIpdata(
              response?.data?.location?.region?.name,
              IpData.state
            ),
            city: CheckIpdata(response?.data?.location?.city, IpData.city),
            zip: CheckIpdata(response?.data?.location?.postal, IpData.zip),
            timezone: CheckIpdata(response?.data?.time_zone, IpData.timezone),
            currencycode: CheckIpdata(
              response?.data?.currency?.code,
              IpData.currencycode
            ),
            countryphoneCode: CheckIpdata(
              response?.data?.location?.country?.calling_code,
              IpData.countryphoneCode
            ),
          })
          const cPhoneCode = response?.data?.location?.country?.calling_code
          setCountryPhoneCode(
            cPhoneCode?.includes(",") ? cPhoneCode?.split(",")[0] : cPhoneCode
          )
          setDefaultCountryCode(
            response?.data?.location?.country?.code?.toLowerCase()
          )
          props.setServerCountry &&
            props.setServerCountry(
              response?.data?.location?.continent?.code || "US"
            )
        })
      }
    })

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        let script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url
        script.id = id
        script.onload = function() {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.CAPTCHA_KEY}`,
      function() {
        // console.log("Script loaded!")
      }
    )
  }, [])

  useEffect(() => {
    let timer = setTimeout(splitFirstAndLastName, 1000)

    return () => clearTimeout(timer)
  }, [name])

  useEffect(() => {
    if (email.length > 0 && checkValidEmail(email)) {
      setEmailError(false)
    }
  }, [email])

  useEffect(() => {
    if (company.length > 0 && !checkInvalidCompany(company)) {
      setCompanyError(false)
    }
  }, [company])

  useEffect(() => {
    if (name.length > 0 && checkValidName(name)) {
      setNameError(false)
    }
  }, [name])

  useEffect(() => {
    if (phoneNum.length > 0 && !checkInvalid(phoneNum)) {
      setPhoneError(false)
    }
  }, [phoneNum])

  useEffect(() => {
    if (isInvalidEmail) {
      setEmailError(true)
    }
  }, [isInvalidEmail])

  const checkEmail = () => {
    if (
      email === "" ||
      !checkValidEmail(email) ||
      (props.disallowCommonDomains && checkCommonDomain(email)) ||
      isInvalidEmail
    ) {
      setEmailError(true)
      setDisableButton(false)
    } else {
      setEmailError(false)
    }
  }

  const checkCompany = () => {
    if (company === "" || checkInvalidCompany(company)) {
      setCompanyError(true)
      setDisableButton(false)
    } else {
      setCompanyError(false)
    }
  }

  const checkName = () => {
    if (name === "" || !checkValidName(name)) {
      setNameError(true)
      setDisableButton(false)
    } else {
      setNameError(false)
    }
  }

  const checkPhone = () => {
    if (
      phoneNum?.slice(dailCodeLength).length === 0 ||
      checkInvalid(phoneNum?.slice(dailCodeLength)) ||
      phoneNum?.slice(dailCodeLength) === undefined
    ) {
      setPhoneError(true)
      setDisableButton(false)
    } else {
      setPhoneError(false)
    }
  }

  const isSubmitValid = () => {
    return (
      email !== "" &&
      checkValidEmail(email) &&
      (props.disallowCommonDomains ? !checkCommonDomain(email) : true) &&
      company !== "" &&
      !checkInvalidCompany(company) &&
      name !== "" &&
      checkValidName(name) &&
      phoneNum !== "" &&
      !checkInvalid(phoneNum)
    )
  }

  //makes a post request and calls a function at the end
  function postRequest(url, data, callback, withCredentials) {
    let xhr = new XMLHttpRequest()

    xhr.open("POST", url)

    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.withCredentials = withCredentials ? true : false

    xhr.send(data)

    xhr.onreadystatechange = () => callback(xhr)
  }

  //Gets Name from user and splits it int o first_name and last_name
  function splitFirstAndLastName() {
    let fullname = name.trim()
    if (fullname.includes(" ")) {
      //split Name if includes a empty space
      setNamef(fullname.substr(0, fullname.indexOf(" ")))
      setNamel(fullname.substr(fullname.indexOf(" ") + 1))
    } else {
      //else set Name as first_name
      setNamef(fullname)
      setNamel("")
    }
  }

  const sendStatusHumioLog = (data, responseText, status) => {
    let log_data_success = {
      finalData: data,
      response: responseText,
    }

    //checks if humio logger is available before pushing
    checkHumioLogger()

    humioLogAvailable &&
      (ErrorMessages[status]
        ? window.logger.info(ErrorMessages[status].loggerInfo, {
            log_data_success,
          })
        : window.logger.info(ErrorMessages.UNKNOWN.loggerInfo, {
            log_data_success,
            responseStatus: status,
          }))
    humioLogAvailable && window.logger.flush()
  }

  //pushes event logs to humio log using beaver-logger
  const sendEventHumioLog = (data, responseText, message) => {
    let log_data = {
      finalData: data,
      response: responseText,
    }

    //checks if humio logger is available before pushing
    checkHumioLogger()

    humioLogAvailable &&
      window.logger.info(message, {
        log_data,
      })
    humioLogAvailable && window.logger.flush()
  }

  const hubspotDataObject = value => {
    //data object for Hubspot form
    const data = {
      fields: [
        {
          name: "firstname",
          value: handleEmptyName(namef),
        },
        {
          name: "lastname",
          value: handleEmptyName(namel),
        },
        {
          name: "email",
          value: DOMPurify.sanitize(email),
        },
        {
          name: "company",
          value: DOMPurify.sanitize(company),
        },
        {
          name: "mobilephone",
          value: DOMPurify.sanitize(phoneNum.slice(dailCodeLength)),
        },
        {
          name: "dc_region",
          value: props.signupEU
            ? serverRegions[props.serverCountry]?.code || "US"
            : "US",
        },
        {
          name: "signup_info",
          value: `${
            window.logger ? "Humio available" : "Humio not Available"
          },  hubspot-cookie : ${
            setHubspotutkCookie("hubspotutk") ? "available" : "not available"
          }, Browser: ${BrowserDetails.browserName}, Browser version: ${
            BrowserDetails.fullVersion
          }, OS : ${BrowserDetails.OSName}, Device type: ${
            BrowserDetails.deviceType
          }, Device resolution: ${BrowserDetails.resolution}`,
        },
        {
          name: "continent",
          value: IpData?.continent || "No Continent",
        },
        {
          name: "continent_code",
          value: IpData?.continentcode || "No Continentcode",
        },
        {
          name: "country_code",
          value: IpData?.countrycode || "No Countrycode",
        },
        {
          name: "country",
          value: IpData?.country || "No Country",
        },
        {
          name: "state",
          value: IpData?.state || "No State",
        },
        {
          name: "city",
          value: IpData?.city || "No City",
        },
        {
          name: "zip",
          value: IpData?.zip || "No Zip",
        },
        {
          name: "country_phone_code",
          value: countryPhoneCode || 0,
        },
        {
          name: "sopsreferral",
          value: sessionStorage.getItem("sopsreff") ?? "No Referral",
        },
        {
          name: "sops_first_page_seen",
          value: sessionStorage.getItem("landing") ?? "No First page seen",
        },
        {
          name: "sops_last_page_seen",
          value: document.location.href,
        },
        {
          name: "current_tool_set",
          value: selectedOptions,
        },
        {
          name: "partner_key",
          value: growsumo?.data?.partner_key || "No Partner Key",
        },
        {
          name: "lead_category",
          value: productType[planType]?.hubspotValue || "No plan",
        },
        {
          name: "technician_size",
          value: techCount || "No technician size",
        },
      ],
      context: {
        hutk: setHubspotutkCookie("hubspotutk"),
        pageUri: document.URL,
        pageName: document.title,
      },
    }
    return data
  }

  const checkSignup = () => {
    checkEmail()
    checkCompany()
    checkName()
    checkPhone()
    if (isSubmitValid()) {
      let final_data = JSON.stringify(hubspotDataObject())
      sendEventHumioLog(final_data, {}, "Before Hubspot Lead push ")
      setDisableButton(true)
      setIsAPILoading(true)
      props.setDisplayError(false)

      //post request for Hubspot
      process.env.SEND_HUBSPOT_DATA_TEST === "true" &&
        postRequest(
          props.formAPI
            ? props.formAPI
            : process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT,
          final_data,
          function(xhr) {
            if (xhr.readyState === 4) {
              handleXHRResult(xhr, "token", hubspotDataObject())
            }
          }
        )

      setTimeout(() => {
        setDisableButton(false)
        setIsAPILoading(false)
        setPopup(!popup)
      }, 1000)
    }
  }

  const handleSubmit = value => {
    // evt.preventDefault()
    checkHumioLogger()

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.CAPTCHA_KEY, { action: "submit" })
        .then(token => {
          if (searchParams?.plan) {
            checkEmail()
            checkCompany()
            checkName()
            checkPhone()
          }

          if (isSubmitValid()) {
            //changes signup button to loading state
            setIsAPILoading(true)

            props.setErrorText("")
            props.setDisplayError(false)

            let final_data = JSON.stringify(hubspotDataObject(value))

            //data object for Signup API
            let datap = {
              emailId: DOMPurify.sanitize(email),

              firstName: handleEmptyName(namef),

              lastName: handleEmptyName(namel),

              phoneNumber: DOMPurify.sanitize(phoneNum.slice(dailCodeLength)),

              companyName: DOMPurify.sanitize(company),

              captchaToken: token,

              mspTimeZone: IpData?.timezone?.id,

              phoneNumberCountryCode: apiphoneNumberCountryCode,

              countryCode: IpData?.countrycode,

              currencyCode: IpData?.currencycode,

              continentCode: IpData?.continentcode,

              productType: productType[planType]?.product,

              plan: searchParams?.plan,

              partner: searchParams?.partner,

              partnerKey: growsumo?.data?.partner_key,

              // //hubspotObject send with signupObject

              hubspotFields: {
                city: IpData?.city,

                country: IpData?.country,

                state: IpData?.state,

                continent: IpData?.continent,

                continentCode: IpData?.continentcode,

                zip: IpData?.zip,

                sopsReferral: sessionStorage.getItem("sopsreff"),

                sopsFirstPageSeen: sessionStorage.getItem("landing"),

                sopsLastPageSeen: document.location.href,

                currentToolSet: selectedOptions,

                signupInfo: `${
                  window.logger ? "Humio available" : "Humio not Available"
                },  hubspot-cookie : ${
                  setHubspotutkCookie("hubspotutk")
                    ? "available"
                    : "not available"
                }, Browser: ${BrowserDetails.browserName}, Browser version: ${
                  BrowserDetails.fullVersion
                }, OS : ${BrowserDetails.OSName}, Device type: ${
                  BrowserDetails.deviceType
                }, Device resolution: ${BrowserDetails.resolution}`,

                hutk: setHubspotutkCookie("hubspotutk"),

                pageUri: document.URL,

                pageName: document.title,
              },
            }
            // if (value === "skipNowBtn") {
            //   datap["productType"] = productType[""]
            // }
            let final_datap = JSON.stringify(datap)
            // console.log("Product Api call", final_datap)

            sendEventHumioLog(final_data, {}, "Before Hubspot Lead push ")

            //post request for Hubspot
            process.env.SEND_HUBSPOT_DATA_TEST === "true" &&
              postRequest(
                props.formAPI
                  ? props.formAPI
                  : process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT,
                final_data,
                function(xhr) {
                  if (xhr.readyState === 4) {
                    handleXHRResult(xhr, token, hubspotDataObject(value))
                  }
                }
              )

            //logs signup send event
            sendEventHumioLog(final_datap, {}, "Before hitting signup API")

            const signupAPI = props.signupEU
              ? serverRegions[props.serverCountry]?.api ||
                serverRegions["US"].api
              : process.env.PRODUCT_SIGNUP_API

            //post request for Signup
            const signupHost =
              window.location.host === "notch.superopsalpha.com"
                ? "https://serv.superopsalpha.com/accounts-web/accounts/customer/signup"
                : signupAPI
            postRequest(
              signupHost,
              final_datap,
              function(xhrp) {
                handleXHRPResult(xhrp, final_datap, hubspotDataObject(value))
              },
              true
            )

            //logs signup send event
            sendEventHumioLog(final_datap, {}, "Signup send event")

            //logs hubspot send event
            // sendEventHumioLog(final_data, "Hubspot Lead ")
          }
        })
    })
  }

  const handleEmptyName = value => {
    return value === "" ? "" : DOMPurify.sanitize(value)
  }

  //handles response from Hubspot
  const handleXHRResult = (xhr, token, data) => {
    if (
      xhr.status === 200 ||
      JSON.parse(xhr.response).errors[0].errorType !== "INVALID_EMAIL"
    ) {
      xhr.status === 200
        ? sendEventHumioLog(
            data,
            xhr.responseText,
            "Hubspot Lead after response"
          )
        : sendEventHumioLog(
            data,
            xhr.responseText,
            "Hubspot Response Failure but valid Email"
          )

      setIsInvalidEmail(false)
    } else {
      // setIsInvalidEmail(true)
      setDisableButton(false)

      //changes signup button back to default state
      setIsAPILoading(false)

      sendEventHumioLog(
        data,
        xhr.responseText,
        "Hubspot Response Failure, with Invalid Email Error"
      )
    }
  }

  //handles reponse from Signup API
  const handleXHRPResult = (xhrp, final_datap, data) => {
    console.log(xhrp.status, "staaaaaa--->")
    if (xhrp.status === 0) {
      props.setDisplayError(true)
      props.setErrorText(ErrorMessages.UNKNOWN.errorText)
      setIsAPILoading(false)
      setDisableButton(false)
    } else {
      props.setDisplayError(false)
    }
    if (xhrp.readyState === 4) {
      let objp = JSON.parse(xhrp.responseText)
      // objp["status"] !== "IN_SESSION" && sendStatusHumioLog(final_datap, xhrp.responseText, objp["status"])
      sendStatusHumioLog(final_datap, xhrp.responseText, objp["status"])
      // sendStatusHumioLog(final_datap, xhrp.responseText, objp)

      data.fields.push({
        name: "signup_info",
        value: `Humio: ${
          window.logger ? "Humio available" : "Humio not Available"
        } , status : ${objp["status"]}, response : ${
          xhrp.responseText
        }, hubspot-cookie : ${
          setHubspotutkCookie("hubspotutk") ? "available" : "not available"
        }`,
      })
      let final_response_data = JSON.stringify(data)
      process.env.SEND_HUBSPOT_DATA_TEST === "true" &&
        postRequest(
          process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT,
          final_response_data,
          function() {
            return
          }
        )

      //if below checks are valid redirects to received subDomain
      if (objp["status"] === "SUCCESS" || objp["status"] === "IN_SESSION") {
        if (growsumo) {
          growsumo.data.name = name
          growsumo.data.email = email
          growsumo.data.customer_key = email
          growsumo.createSignup()
        }

        !isAPILoading && setIsAPILoading(true)
        props.setDisplayError(false)

        let getHost = window.location.host
        let validHosts = [
          "superopsalpha.com",
          "superops.com",
          "www.superops.com",
        ]
        if (validHosts.includes(getHost)) {
          const encodedToken = encodeURIComponent(objp["token"])
          getHost =
            getHost === "superops.com" || getHost === "www.superops.com"
              ? "superops.ai"
              : getHost
          const srcValue = props.signupEU
            ? serverRegions[props.serverCountry]?.srcParam ||
              serverRegions["US"].srcParam
            : objp["subDomainName"]
          window.location.href = `/signup-tezt/thank-you?src=${srcValue}&host=${getHost}&token=${encodedToken}&subdomain=${objp["subDomainName"]}`
          // window.location.href = `https://${objp["subDomainName"]}.${getHost}/accounts-web/accounts/loadaccountdetails?CustomerSubDomain=${objp["subDomainName"]}&token=${encodedToken}`
        } else {
          window.location.href = `/signup/thank-you?src=${objp["subDomainName"]}`
        }
      } else {
        //changes signup button back to default state
        setIsAPILoading(false)

        setDisableButton(false)
        props.setDisplayError(true)
        props.setErrorText(
          ErrorMessages[objp["status"]]
            ? ErrorMessages[objp["status"]].errorText
            : ErrorMessages.UNKNOWN.errorText
        )
      }
    }
  }

  const handleOnChange = (value, data, event, formattedValue) => {
    setPhone(value)
    setDailCodeLength(data.dialCode.length)
    setapiphoneNumberCountryCode(data.dialCode.toString())
  }

  return (
    <>
      <div className=" forms">
        {popup && (
          <PopupPlanTech
            visibility={popup}
            setPopup={setPopup}
            callbackFn={val => handleSubmit(val)}
            planType={planType}
            setPlanType={setPlanType}
            techCount={techCount}
            setTechCount={setTechCount}
            displayError={props.displayError}
            errorText={props.errorText}
            name={name.split(" ")[0]}
            isAPILoading={isAPILoading}
            setIsAPILoading={setIsAPILoading}
            disableButton={disableButton}
            setDisableButton={setDisableButton}
          />
        )}
        <form>
          <div className="position-relative">
            <input
              id="name"
              type="text"
              name="NAME"
              style={{
                border: nameError ? "1px solid #ff0055" : "1px solid #190429",
              }}
              className={`fs name ${name.length > 0 ? "valid" : ""} ${
                name.length > 40 && nameIsOffFocus ? "input-elipse" : ""
              }`}
              value={name}
              onBlur={() => setNameIsOffFocus(true)}
              onFocus={() => setNameIsOffFocus(false)}
              onChange={e => setName(e.target.value)}
            />
            <span className="floating-label p14 position-absolute">
              {" "}
              <p>Name</p>
            </span>
            {name.length > 0 && nameIsOffFocus && (
              <span className="greeting position-absolute p12">
                <p>nice to meet you</p>
              </span>
            )}
            <div
              className="error-message error-bg er-ct"
              style={{ display: nameError ? "block" : "none" }}
            >
              Please enter a valid name.
            </div>
          </div>

          <div className="position-relative">
            <input
              id="email"
              type="text"
              name="EMAIL"
              value={email}
              className={`email ${email.length > 0 ? "valid" : ""} ${
                email.length > 40 && emailIsOffFocus ? "input-elipse" : ""
              }`}
              style={{
                border: emailError ? "1px solid #ff0055" : "1px solid #190429",
              }}
              onBlur={() => setEmailIsOffFocus(true)}
              onFocus={() => setEmailIsOffFocus(false)}
              onChange={e => setEmail(e.target.value.trim())}
            />
            <span className="floating-label p14 position-absolute">
              {" "}
              <p>Business email</p>
            </span>
            {email.length > 0 && emailIsOffFocus && (
              <span className="greeting position-absolute p12">
                <p>we wont spam you</p>
              </span>
            )}
            <div
              className="error-message error-bg er-ct"
              style={{ display: emailError ? "block" : "none" }}
            >
              Please enter a valid business email.
            </div>
          </div>

          <div className="position-relative">
            <input
              id="company"
              type="text"
              name="COMPANY"
              value={company}
              className={`company ${company.length > 0 ? "valid" : ""} ${
                company.length > 35 && compIsOffFocus ? "input-elipse" : ""
              }`}
              style={{
                border: companyError
                  ? "1px solid #ff0055"
                  : "1px solid #190429",
              }}
              onBlur={() => setCompIsOffFocus(true)}
              onFocus={() => setCompIsOffFocus(false)}
              onChange={e => setCompany(e.target.value)}
            />
            <span className="floating-label p14 position-absolute">
              {" "}
              <p>Company name</p>
            </span>
            {company.length > 0 && compIsOffFocus && (
              <span className="greeting position-absolute p12">
                <p>to create a domain for you</p>
              </span>
            )}
            <div
              className="error-message error-bg er-ct"
              style={{ display: companyError ? "block" : "none" }}
            >
              Please enter a valid company name.
            </div>
          </div>

          <div
            className={`position-relative phone-number ${
              companyError ? "phone-error" : ""
            }`}
          >
            <PhoneInput
              id="phone"
              name="PHONENUMBER"
              country={defaultCountryCode}
              value={phoneNum}
              placeholder="Phone number"
              onBlur={() => setPhoneIsOffFocus(true)}
              onChange={handleOnChange}
              enableLongNumbers
            />
            {phoneNum.length > 11 && phoneIsOffFocus && (
              <span className="greeting position-absolute p12">
                <p>we respect your privacy</p>
              </span>
            )}
            <div
              className="error-message error-bg er-ct"
              style={{ display: phoneError ? "block" : "none" }}
            >
              Please enter a valid phone number.
            </div>
          </div>

          {/* <div className={`position-relative`}>
            <Select
              id="select"
              choices={choices}
              name="CURRENTTOOLSET"
              label={"Choose what you currently use"}
              onChange={val => {
                let options = val.map(v => v.value)
                setSelectedOptions(options.join(", "))
              }}
            />
            <div
              className="error-message error-bg er-ct"
              style={{
                display: currentToolError ? "block" : "none",
              }}
            >
              {" "}
              <p>Please select a value</p>{" "}
            </div>
          </div> */}

          <div
            className={`ct-btn ${isAPILoading ? "active-loading-ct-btn" : ""}`}
          >
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                //disables button on single click
                setDisableButton(true)
                // handleSubmit()
                checkSignup()
              }}
              disabled={disableButton}
            >
              {isAPILoading ? (
                <div className="loading-anim">
                  LOADING
                  <span className="ellipsis-anim">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </span>
                </div>
              ) : (
                "GET STARTED FOR FREE"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  )
})

export default SignupFormTest
